<template>
  <div class="app-container order-verify-list">
    <div class="filter-line">
      <span>
        <el-button plain icon="el-icon-refresh-right" @click="freshHandler"
          >刷新</el-button
        >
      </span>
      <div
        class="header-filter-button"
        @click="dataset.filterLine.show = !dataset.filterLine.show"
      >
        筛选
        <i class="el-icon-arrow-down" v-if="dataset.filterLine.show"></i>
        <i class="el-icon-arrow-right" v-else></i>
      </div>
    </div>
    <div class="table-container">
      <FinalTable
        ref="finalTableRef"
        :datas="dataset"
        @tableEventHandler="tableEventHandler"
      ></FinalTable>
    </div>
    <el-dialog
      :visible.sync="rejectFormShow"
      :before-close="
        () => {
          rejectFormShow = false;
        }
      "
      title="驳回申请"
      width="728px"
      :destroy-on-close="true"
      :close-on-click-modal="false"
    >
      <el-form
        :model="ruleForm"
        :rules="rules"
        label-position="top"
        ref="ruleForm"
        label-width="100px"
        class="demo-ruleForm"
      >
        <el-form-item label="驳回原因" prop="examineFailReason">
          <el-input
            maxlength="40"
            placeholder="请输入驳回原因"
            v-model="ruleForm.examineFailReason"
          >
          </el-input>
        </el-form-item>
        <el-row>
          <el-form-item align="right">
            <el-button
              @click="
                () => {
                  rejectFormShow = false;
                }
              "
              >取消</el-button
            >
            <el-button type="primary" @click="submitForm('ruleForm')"
              >提交</el-button
            >
          </el-form-item>
        </el-row>
      </el-form>
    </el-dialog>
  </div>
</template>

<script>
import FinalTable from "@/components/FinalTable";
import { toExamineListForPC } from "@/api/business/order/tenant/order";
export default {
  name: "orderVerifyList",
  components: {
    FinalTable,
  },
  data() {
    return {
      loadingFlag: false,
      listQuery: {
        current: 1,
        rowCount: 10,
        auditType: null,
      },
      rejectForm: {},
      rejectFormShow: false,
      ruleForm: {
        examineFailReason: "",
        orderId: "",
      },
      rules: {
        examineFailReason: [
          { required: true, message: "请输入驳回原因", trigger: "blur" },
        ],
      },
      dataset: {
        paginationConfig: {
          need: true,
        },
        filterLine: {
          show: true,
        },
        selection: {
          need: false,
        },
        header: [
          {
            prop: "auditType",
            label: "审核状态",
            width: "",
          },
          {
            prop: "submitUserName",
            label: "申请人",
            width: "",
          },
          {
            prop: "contentDesc",
            label: "工单",
            width: "",
          },
          {
            prop: "requestDataStr",
            label: "发起时间",
            width: "",
          },
          {
            prop: "ordPersonStr",
            label: "审核人",
            width: "",
          },
          {
            prop: "operation",
            label: this.$t("commons.actions"),
            width: "120",
          },
        ],
        tableData: [],
        searchLineConfig: {
          auditType: {
            type: "selector",
            label: "审核状态",
            value: "",
            placeholder: "请选择审核状态",
            actionType: "goSearch",
            optionList: [
              {
                value: 1,
                label: "延时待审核",
              },
              {
                value: 2,
                label: "协助待审核",
              },
              {
                value: 3,
                label: "转派待审核 ",
              },
              {
                value: 4,
                label: "物料待审核 ",
              },
              {
                value: 5,
                label: "待验收 ",
              },
              {
                value: 6,
                label: "报单待审核",
              },
              {
                value: 7,
                label: "关单待审核",
              },
            ],
          },
        },
        detailConfig: {
          requestDataStr: {
            type: "dateFormat",
          },
          auditType: {
            type: "enumerationColumn",
            emuList: {
              1: "延时待审核",
              2: "协助待审核",
              3: "转派待审核 ",
              4: "物料待审核 ",
              5: "待验收 ",
              6: "报单待审核",
              7: "关单待审核",
            },
          },
          operation: {
            type: "icon",
            iconList: [
              {
                actionType: "iconClick",
                eventName: "view",
                fontSize: "14px",
                color: "#1A4CEC",
                cursorPointer: "pointer",
                iconName: "el-icon-view",
                tooltips: "查看",
              },
              {
                actionType: "iconClick",
                eventName: "submit",
                fontSize: "14px",
                color: "#1A4CEC",
                cursorPointer: "pointer",
                iconName: "el-icon-view",
                tooltips: "通过",
              },
              {
                actionType: "iconClick",
                eventName: "reject",
                fontSize: "14px",
                color: "#1A4CEC",
                cursorPointer: "pointer",
                iconName: "el-icon-view",
                tooltips: "驳回",
              },
            ],
          },
        },
        advanceFilterConfig: {},
        pageVO: {
          current: 1,
          rowCount: 10,
          total: 0,
        },
      },
    };
  },
  watch: {
    loadingFlag: {
      handler(val) {
        this.$refs.finalTableRef.loadingToggle(val);
      },
    },
  },
  created() {
    this.getTableList();
  },
  methods: {
    getTableList() {
      this.loadingFlag = true;
      toExamineListForPC(this.listQuery)
        .then((res) => {
          res.rows.forEach((item) => {
            item.requestDataStr = item.auditUserList[0].creationDate;
            // 申请人: 取提交审核的申请人，报单审核的申请人是报单人，其他各类审核为执行人
            item.submitUserName =
              item.auditType === 6
                ? item.submitUserName
                : item.handlePersonName;
            item.ordPersonStr =
              item.auditUserList && item.auditUserList.length
                ? item.auditUserList
                    .map((item) => {
                      return `${item.userName}(${
                        item.auditStatus === 1 ? "待审核" : "通过"
                      })`;
                    })
                    .join(";")
                : "-";
          });
          this.dataset.tableData = res.rows;
          this.dataset.pageVO.total = res.total;
        })
        .finally(() => {
          this.loadingFlag = false;
        });
    },
    freshHandler() {
      this.getTableList();
    },
    rejectHandler({
      orderId,
      auditType,
      orderAssistId,
      orderDelayedId,
      orderMaterielId,
      orderTransferId,
    }) {
      const paramIdMap = {
        1: orderDelayedId,
        2: orderAssistId,
        3: orderTransferId,
        4: orderMaterielId,
      };
      const paramIdNameMap = {
        1: "orderDelayedId",
        2: "orderAssistId",
        3: "orderTransferId",
        4: "orderMaterielId",
      };
      let requestId = paramIdMap[auditType] || orderId;
      let requestKey = paramIdNameMap[auditType] || "orderId";
      this.rejectForm = {
        [requestKey]: requestId,
      };
      this.ruleForm.auditType = auditType;
      this.ruleForm.examineFailReason = "";
      this.ruleForm.orderId = orderId;
      this.rejectFormShow = true;
    },
    tableEventHandler(datas) {
      if (datas.type === "goSearch") {
        // 处理时间参数
        this.listQuery = { ...this.listQuery, ...datas.params };
        this.getTableList();
      } else if (datas.type === "paginationChange") {
        this.listQuery.current = datas.params.current.page;
        this.listQuery.rowCount = datas.params.current.limit;
        this.getTableList();
      } else if (datas.type === "iconClick") {
        switch (datas.eventName) {
          case "view":
            this.viewHandler(datas.row);
            break;
          case "submit":
            this.submitHandler(datas.row);
            break;
          case "reject":
            this.rejectHandler(datas.row);
            break;
        }
      }
    },
    viewHandler(row) {
      //    this.$message.info("跳转工单详情");
      //查看工单详情
      let params = {
        path: "/iot/orderQuery/detail",
        query: {
          auditType: row.auditType,
          orderId: row.orderId,
        },
      };
      this.$router.push(params);
    },
    submitHandler({
      orderId,
      auditType,
      orderAssistId,
      orderDelayedId,
      orderMaterielId,
      orderTransferId,
    }) {
      const paramIdMap = {
        1: orderDelayedId,
        2: orderAssistId,
        3: orderTransferId,
        4: orderMaterielId,
      };
      const paramIdNameMap = {
        1: "orderDelayedId",
        2: "orderAssistId",
        3: "orderTransferId",
        4: "orderMaterielId",
      };
      let requestId = paramIdMap[auditType] || orderId;
      let requestKey = paramIdNameMap[auditType] || "orderId";
      this.ruleForm.auditType = auditType;
      this.ruleForm.examineFailReason = "";
      this.ruleForm.orderId = orderId;
      this.doRequest(auditType, { [requestKey]: requestId });
    },
    submitRejectHandler(auditType) {
      const reasonMaps = {
        1: "examineFailReason",
        2: "examineFailReason",
        3: "refuseReason",
        4: "toExamineOpinion",
        5: "examineFailReason",
        6: "examineFailReason",
        7: "examineFailReason",
      };
      this.rejectForm[reasonMaps[auditType]] = this.ruleForm.examineFailReason;
      this.doRequest(auditType, this.rejectForm);
    },
    submitForm(formName) {
      this.$refs[formName].validate((valid) => {
        if (valid) {
          this.submitRejectHandler(
            this.ruleForm.auditType,
            this.ruleForm.orderId
          );
        } else {
          console.log("error submit!!");
          return false;
        }
      });
    },
    async doRequest(auditType, params) {
      //    const requestMaps = {
      //      0: "/order/tenant/orderDelayed/auditDelayed",
      //      1: "/order/tenant/orderAssist/auditAssist",
      //      2: "/order/tenant/orderTransfer/auditTransfer",
      //      3: "/order/tenant/orderMateriel/auditMateriel",
      //      4: "/order/tenant/orderFill/auditImplement",
      //      5: "/order/tenant/orderFill/reportAudit",
      //      6: "/order/tenant/orderFill/auditCloseOrder",
      //    };
      let rqData = this.common.getOrderAuditParams(auditType);
      if (!rqData) return;
      await this.ApiHttp(rqData.urls, params, "put");
      this.$message.success("操作成功！");
      this.getTableList();
      this.rejectFormShow = false;
    },
  },
};
</script>

<style lang="less" scoped>
.order-verify-list {
  .filter-line {
    margin-bottom: 18px;
    display: flex;
    align-items: center;
    justify-content: space-between;
    button {
      height: 36px;
    }
    .icon-loudoutu {
      font-size: 12px;
    }
    .header-filter-button {
      cursor: pointer;
      width: 84px;
      height: 36px;
      line-height: 36px;
      text-align: center;
      border-radius: 3px;
      font-size: 14px;
      font-family: PingFangSC-Regular, PingFang SC;
      font-weight: 400;
      border: 1px solid #e7e8eb;
    }
    .not-allowed-button {
      cursor: not-allowed;
      background-color: #fef0f0;
      border-color: #fde2e2;
      color: #f9a7a7;
    }
  }
}
</style>